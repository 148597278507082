<template>
  <div class="const_width">
    <div class="left_part">
      <div class="content_container" v-if="match">
        <scoreboard :match="match" :response="response"/>
        <v-tabs centered slider-size="3"  v-model="tabModel" height="32" color="black">
          <template v-if="this.tr === activeLanguage()">
            <v-tab class="text-capitalize"
                   v-for="item in tabsWithTr"
                   :key="item.id"
                   :to="item.to"
            >
              {{ item.text }}
            </v-tab>
          </template>

          <template v-if="this.en === activeLanguage()">
            <v-tab class="text-capitalize"
                   v-for="item in tabsWithEn"
                   :key="item.id"
                   :to="item.to"
            >
              {{ item.text }}
            </v-tab>
          </template>
        </v-tabs>
        <router-view/>
      </div>
    </div>
    <div class="right_part">
      <div class="match_detail_bilyoner_container">
        <a href="https://app.adjust.com/azvq2wg?redirect=https%3A%2F%2Fwww.bilyoner.com%2Fiddaa%3Futm_source%3Dfutbolverileri"
           target="_blank">
          <v-img
              max-width="313"
              max-height="630"
              width="100%"
              src="@/assets/bilyoner/premierLeague.png"
              lazy-src="@/assets/bilyoner/premierLeague.png"
          />
        </a>
      </div>
      <div class="content_container" v-if="match[0] && match[1]">
        <stats-and-standings v-if="match[0].events.length !== 0 && match[1].events.length !== 0 " :standings="standings" :isLive="this.live"/>
      </div>
    </div>
  </div>
</template>

<script>
import Scoreboard from "./Scoreboard";
import {fetchMatch, fetchMatchesHead, fetchStandings} from "../../api";
import StatsAndStandings from "./StatsAndStandings";

export default {
  name: "MatchDetail",
  components: {StatsAndStandings, Scoreboard},
  data() {
    return {
      tabModel: 0,
      match: null,
      team: null,
      response: null,
      seasonId: null,
      standings: [],
      groupsName: null,
      live: null,
      poll: null,
      tr: false,
      en: false,
      tabsWithEn: [
        {text: 'Match Info', to: {name: 'MDInfo'}},
        {text: 'Compare', to: {name: 'MDCompare'}},
        //{text: 'Forum', to: {name: 'MDForum'}},
      ],
      tabsWithTr: [
        {text: 'Maç Bilgisi', to: {name: 'MDInfo'}},
        {text: 'Karşılaştırma', to: {name: 'MDCompare'}},
        //{text: 'Forum', to: {name: 'MDForum'}},
      ]
    }
  },

  mounted() {
    this.fetchMatch();
    this.poll = setInterval(() => {
      this.fetchMatch();
    }, 60000);
  },

  methods: {
    activeLanguage() {
      if (localStorage.getItem('language') === 'tr') {
        return this.tr = true;
      } else {
        return this.en = true;
      }
    },
    async fetchMatch() {
      const data = await fetchMatch(this.$route.params.id);
      const response = await fetchMatchesHead(this.$route.params.id);
      this.response = response.data.data[0];
      this.groupsName = this.response.competitionName;
      this.seasonId = this.response.seasonId;
      if (this.response.status !== 'Fixture') {
        this.match = data.data.data.eventPeriods;
        this.team = response.data.data[0]
        this.live = true
      } else {
        this.match = data.data.data
        this.live = false
      }
      await this.fetchStandings(response.data.data[0].seasonId);
      if (data.data.data.ended) {
        clearInterval(this.poll);
      }
    },

    async fetchStandings(id) {
      //const data = await api.get(`api/fv/seasons/${sid}/standings`);
      const data = await fetchStandings(id);
      let groupsName = data.data.data.groups[0].name
      if (this.match && groupsName) {
        this.standings = data.data.data.groups[0].rows.filter(g => g.name === groupsName) || [];
      } else {
        this.standings = data.data.data.groups[0].rows;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.poll);
  },
}
</script>

<style>
.match_detail_bilyoner_container {
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
