<template>
    <div>
        <v-skeleton-loader
                v-if="!match"
                type="image"
                height="154"
                tile
        ></v-skeleton-loader>
        <div v-if="match" class="md_header">
            <div class="md_header_title_bar">
                <div class="font-weight-bold">{{response.areaName}}: {{response.competitionName}}</div>
                <div class="md_match_date">{{matchDate()}}</div>
            </div>
            <div class="md_header_scoreboard">
                <div class="md_home">
                    <div>
                        <img class="md_team_logo" @click="toTeamDetail(response.homeTeamId)"
                             :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${response.homeTeamId}.png`" alt=""/>
                    </div>
                    <div class="md_team_name">
                    <span @click="toTeamDetail(response.homeTeamId)">{{response.homeTeam}}</span>
                    </div>
                </div>
                <div class="md_score">
                    <div class="md_score_status">
                        <span v-if="response.ended">MS</span>
                        <span class="live" v-if="response.live && response.halfTime">İY</span>
                        <span class="live" v-if="response.live && !response.halfTime">{{response.min}}'</span>
                        <span v-if="response.status === 'Fixture' && response.status !== 'Postponed'">{{startTime(response.dateTimeUtc)}}</span>
                        <span v-if="response.status === 'Fixture' && response.status === 'Postponed'">Ert.</span>
                    </div>
                    <div class="md_score_score" :class="{'live': response.live}">
                        <span v-if="response.status !== 'Fixture'">{{ response.hscoreFT }} - {{ response.ascoreFT }}</span>
                        <span v-else>-</span>
                    </div>
                    <div class="md_score_half_time">
                        <span v-if="response.halfTime">({{response.hscoreHT}} - {{response.ascoreHT}})</span>
                    </div>
                </div>
                <div class="md_away">
                    <div class="md_team_name">
                        <span @click="toTeamDetail(response.awayTeamId)">{{response.awayTeam}}</span>
                    </div>
                    <div>
                        <img class="md_team_logo" @click="toTeamDetail(response.awayTeamId)"
                             :src="`https://d17jyjgv9729qk.cloudfront.net/assets/img/teams/${response.awayTeamId}.png`" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from "dayjs";

    export default {
        name: "Scoreboard",
        props: ['match','response'],
        methods: {
            matchDate() {
                return dayjs(this.response.dateTimeUtc).format("YYYY-MM-DD - HH:mm")
            },

            startTime() {
                return dayjs(this.response.dateTimeUtc).format("HH:mm")
            },

            toTeamDetail(id){
                let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
                window.open(routeData.href, '_blank');
            }
        },
    }
</script>

<style scoped>
    .md_header {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 24px auto;
        padding: 10px 15px;
    }

    .md_header_title_bar {
        display: grid;
        grid-template-columns: 50% 50%;
        font-size: 12px;
        line-height: 24px;
    }

    .md_header_scoreboard {
        display: grid;
        grid-template-columns: calc(50% - 40px) 80px calc(50% - 40px);
        padding: 15px 0;
    }

    .md_match_date {
        text-align: right;
        color: #b2b2b2;
    }

    .md_score {
        display: grid;
        grid-template-rows: 26px 30px 24px;
        text-align: center;
    }

    .md_score_status {
        font-size: 16px;
        color: #b2b2b2;
    }

    .md_score_score {
        font-size: 24px;
        font-weight: bold;
    }

    .md_score_half_time {
        padding-top: 5px;
        font-size: 14px;
        color: #b2b2b2;
    }

    .md_team_name span{
        cursor: pointer;
    }

    .md_team_logo{
        cursor: pointer;
    }

    @media only screen and (min-width: 650px) {
        .md_home {
            display: grid;
            grid-template-columns: 80px auto;
        }

        .md_away {
            display: grid;
            grid-template-columns: auto 80px;
        }

        .md_away .md_team_name {
            text-align: right;
        }

        .md_team_name {
            align-self: center;
            font-size: 1rem;
            font-weight: bold;
            padding: 0 15px;
        }

        .md_team_logo {
            display: block;
            width: 80px;
            height: 80px;
        }
    }

    @media only screen and (max-width: 650px) {
        .md_home {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: 60px auto;
        }

        .md_away {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: 60px auto;
            grid-template-areas: "logo" "name";
        }

        .md_away .md_team_logo {
            grid-area: logo;
        }

        .md_away .md_team_name {
            grid-area: name;
        }

        .md_team_logo {
            margin: 0 auto;
        }

        .md_team_name {
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            padding-top: 10px;
        }

        .md_team_logo {
            display: block;
            width: 60px;
            height: 60px;
        }
    }

    @media only screen and (max-width: 426px) {
      .font-weight-bold {
        font-size: 12px;
        line-height: 15px;
      }
    }

</style>
