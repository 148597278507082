<template>
  <div>
    <v-skeleton-loader
        v-if="stats.length < 1"
        tile
        type="table-heading@16"
    ></v-skeleton-loader>
    <template v-if="stats.length > 0 && !started && stats">
      <h2 class="fv_title mt-0">İstatistikler</h2>
      <div>
        <template v-for="s in stats">
          <div v-for="i in s.items" :key="i" class="stats_row">
            <div class="stats_row_bar">
              <v-progress-linear
                  color="blue"
                  height="8"
                  reverse
                  :value="formatBarValue(i.home, i)"
              />
            </div>
            <div class="stats_row_value">{{ i.home }}</div>
            <div class="stats_row_stat">{{ i.name }}</div>
            <div class="stats_row_value">{{ i.away }}</div>
            <div class="stats_row_bar">
              <v-progress-linear
                  color="orange"
                  height="8"
                  :value="formatBarValue(i.away, i)"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-if="standings">
      <h2 class="fv_title" :class="{'mt-0': !started}">Puan Durumu</h2>
      <div>
        <div class="standing_header _header _accent--text">
          <div class="sh_num">#</div>
          <div class="sh_team_name">Takım</div>
          <div class="sh_value">O</div>
          <div class="sh_value">G</div>
          <div class="sh_value">B</div>
          <div class="sh_value">M</div>
          <div class="sh_value">P</div>
        </div>
        <div class="standing_row"
             v-for="(t, ti) in standings"
             :key="ti"
             :id="`std-${t.teamId}`"
             :class="standingTeamNameClass(t.teamId)"
        >
          <div class="sh_num">{{ t.position }}</div>
          <div class="sh_team_name">
            <span @click="toTeamDetail(t.teamId)">{{ t.teamName }}</span>
          </div>
          <div class="sh_value">{{ t.played }}</div>
          <div class="sh_value">{{ t.win }}</div>
          <div class="sh_value">{{ t.draw }}</div>
          <div class="sh_value">{{ t.loss }}</div>
          <div class="sh_value">{{ t.points }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {fetchStats} from "../../api";

export default {
  name: "StatsAndStandings",

  props: ['standings', 'isLive'],

  data() {
    return {
      stats: [],
      STAT_MAP,
      interval: null,
    }
  },

  mounted() {
    this.fetchStats();
    if (this.isLive) {
      this.interval = setInterval(() => {
        this.fetchStats();
      }, 10000);
    }
  },

  methods: {
    async fetchStats() {
      const data = await fetchStats(this.$route.params.id);
      if (this.isLive) {
        this.stats = data.data.data.periods[0].groups;
      } else {
        this.stats = data.data.data
      }
    },

    formatBarValue(val, stat) {
      if (val && stat) {
        return val * 100 / (stat.home + stat.away);
      }
    },

    standingTeamNameClass(id) {
      if (this.$parent.$data.response) {
        if (id === this.$parent.$data.response.homeTeamId) {
          return 'blue--text';
        } else if (id === this.$parent.$data.response.awayTeamId) {
          return 'orange--text';
        } else {
          return '';
        }
      }
    },

    toTeamDetail(id) {
      let routeData = this.$router.resolve({name: 'TeamDetail', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
  },

  computed: {
    started() {
      return this.$parent.$data.response ? this.$parent.$data.response.notStarted : false;
    }
  },

  watch: {
    isLive(value) {
      if (value && !this.interval) {
        this.interval = setInterval(() => {
          this.fetchStats();
        }, 10000);
      } else if (!value && this.interval) {
        clearInterval(this.interval);
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
  }

}

const STAT_MAP = {
  'ball_possession': 'Topla Oynama',
  'shots_total': 'Toplam Şut',
  'shots_on_target': 'İsabetli Şut',
  'shots_off_target': 'İsabetsiz Şut',
  'shots_saved': 'Kurtarılan Şut',
  'shots_blocked': 'Bloklanan Şut',
  'yellow_cards': 'Sarı Kart',
  'yellow_red_cards': 'Kırmızı Kart',
  'red_cards': 'Çift Sarı',
  'corner_kicks': 'Korner',
  'goal_kicks': 'Kale Vuruşu',
  'free_kicks': 'Serbest Vuruş',
  'penalty_awarded': 'Penaltı',
  'penalty_missed': 'Kaçan Penaltı',
  'fouls': 'Faul',
  'offsides': 'Ofsayt',
  'throw_ins': 'Taç',
};
</script>

<style>
.stats_row {
  display: grid;
  place-items: center;
  grid-template-columns: 60px 30px auto 30px 60px;
  grid-template-rows: 23px;
  line-height: 23px;
  border-bottom: 1px solid #edf2f4;

}

.stats_row_stat, .stats_row_value {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats_row_value {
  font-weight: bold;
}

.stats_row_bar {
  width: 45px;
  display: flex;
  place-items: center;
}

.standing_header {
  display: grid;
  grid-template-columns: 24px minmax(60px, 300px) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto);
  grid-template-rows: 23px;
  line-height: 23px;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #edf2f4;
}

.standing_row {
  display: grid;
  grid-template-columns: 24px minmax(60px, 300px) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto) minmax(26px, auto);
  grid-template-rows: 23px;
  line-height: 23px;
  border-bottom: 1px solid #edf2f4;
}

.sh_num {
  border-right: 1px solid #edf2f4;
}

.sh_num, .sh_value {
  text-align: center;
}

.sh_num {
  font-weight: bold;
}

.sh_team_name {
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sh_team_name span {
  cursor: pointer;
}
</style>
